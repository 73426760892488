<template lang="pug">
div(class='space-y-3')
  template(v-for='facet in instance.state.value.facets' :key='facet.name')
    //- TODOLATERER: Build a range facet component if consytructor wins

    template(v-if='facet.name === "sameDayShipping"')
      ConstructorioFacetSameDayShipping(:is-light='isLight')

    template(v-else)
      ConstructorioFacet(
        :facet='facet'
        :is-light='isLight'
        :is-collapsed-default='isCollapsedDefault'
        :show-highlighted-border-when-refined='showHighlightedBorderWhenRefined'
        :show-selected-refinements-when-collapsed='showSelectedRefinementsWhenCollapsed'
      )
</template>

<script setup lang="ts">
const { instance } = useConstructorInject()

defineProps<{
  isLight?: boolean
  isCollapsedDefault?: boolean
  showHighlightedBorderWhenRefined?: boolean
  showSelectedRefinementsWhenCollapsed?: boolean
}>()
</script>
