<template lang="pug">
ConstructorioFacet(
  v-if='categories.length > 0'
  label='Category'
  :is-light='isLight'
  :is-collapsed-default='isCollapsedDefault'
)
  template(#options)
    template(v-for='category in categories' :key='category.id')
      UniversalLink(class='block text-dark' :link='category.link') {{ category.label }}
</template>

<script setup lang="ts">
const { categoryData } = defineProps<{
  categoryData: any
  isLight?: boolean
  isCollapsedDefault?: boolean
}>()

const route = useRoute()

const { instance } = useConstructorInject()

const categories = computed(() => {
  const [parent] = instance.state.value.groups

  const children = parent?.children ?? []

  const groups = getSubGroups(children)

  const queryStringParams = new URLSearchParams()

  // If on brand page then add the brand to query string
  if (categoryData?.type === 'brand') queryStringParams.append('filter', `brand:${categoryData.name}`)
  if (categoryData?.type !== 'brand' && route.query.sameDayShipping)
    queryStringParams.append('sameDayShipping', route.query.sameDayShipping.toString())

  const query = queryStringParams.size > 0 ? `?${queryStringParams.toString()}` : ''

  return groups.map((group) => {
    return {
      id: group.group_id,
      label: group.display_name,
      link: `/c/${group.data?.slug}${query}`,
    }
  })
})

function getSubGroups<T extends { children?: T[] }>(groups: T[]): T[] {
  return groups.flatMap((group) => (group.children && group.children.length > 0 ? getSubGroups(group.children) : group))
}
</script>
